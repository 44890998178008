<template>
    <div>
        <Modal
            v-model="modal_add_player"
            title="编辑路由"
            @on-ok='addNewPlayer'
        >
            <playerSearch  v-model="new_player" > </playerSearch>
        </Modal>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="基础信息" name="1">
            <Form label-position="left" :label-width="80" >
                <FormItem label="id: " v-if="data.id">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.id" disabled></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="赛事: ">
                    <Row>
                        <Col span="20" >
                            <competitionSearch v-model="data.competition"></competitionSearch>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="奥运会: ">
                    <Row>
                        <Col span="20" >
                            <seasonSearch v-model="data.season"></seasonSearch>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="选手: ">
                    <Row v-for="(item, index) in data.athletes" :key="index" >
                        <Col span="22" >
                            <Row>
                                <Col span="8" >
                                    <span class="text-main"> {{item.name}} </span>
                                </Col>
                                <Col span="12" >
                                    <span class="text-link"> {{item.id}} </span>
                                </Col>
                                <Col span="4" >
                                    <Icon type="md-close-circle"  @click.native="data.athletes.splice(index, 1)" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span="20" >
                            <Button shape="circle" icon="md-add" @click="modal_add_player=true" ></Button>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="代表团: ">
                    <Row>
                        <Col span="20" >
                            <delegateSearch v-model="data.delegate"></delegateSearch>
                        </Col>
                    </Row>
                </FormItem>
                
                <FormItem label="国家/地区: ">
                    <Row>
                        <Col span="20" >
                            <areaSearch v-model="data.area"></areaSearch>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="大运动项: ">
                    <Row>
                        <Col span="20" >
                            <sportSearch v-model="data.sport" :season_id="data.season.id" ></sportSearch>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="小运动项: ">
                    <Row>
                        <Col span="20" >
                            <subsportSearch v-model="data.sup_sport" :season_id="data.season.id" :sport_id="data.sport.id"></subsportSearch>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="赛果: ">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.result" ></Input>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="单位: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.unit" >
                                <Option v-for="item in $conf.olympics.result_units" :key="item.id" :value="item.id" >{{item.name}}</Option>
                            </Select>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="时间: " >
                    <Row>
                        <Col span="17" >
                            <DatePicker 
                                type="datetime" 
                                format="yyyy-MM-dd HH:mm" 
                                style="width: 100%"
                                v-model="date"
                            >
                            </DatePicker>
                        </Col>
                        <Col span="3" >
                            <a href="javascript:void(0)" @click="now()">此刻</a>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="奖牌类型: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.type" >
                                <Option :value="0" key="0">未知</Option>
                                <Option :value="1" key="1">金牌</Option>
                                <Option :value="2" key="2">银牌</Option>
                                <Option :value="3" key="3">铜牌</Option>
                            </Select>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem label="是否删除: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.deleted" >
                                <Option :value="1" key="1">是</Option>
                                <Option :value="0" key="0">否</Option>
                            </Select>
                        </Col>
                    </Row>
                </FormItem>

            </Form>
            </TabPane>
        </Tabs>
        <Row align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import { getMedalList, updateMedal } from '@/api/olympics/medal';
import competitionSearch from '../competition/search.vue';
import seasonSearch from '../season/search.vue';
import delegateSearch from '../delegation/search.vue';
import playerSearch from '../player/search.vue';
import areaSearch from '../area/search.vue';
import sportSearch from '../sport/search.vue';
import subsportSearch from '../subsport/search.vue';
import moment from 'moment';
import { getPlayerList } from '@/api/olympics/player';
import { getDelegationPlayerList } from '@/api/olympics/delegation_player';


export default {
    name: "medal-edit",
    props: {
        _id: Number,   // id
    },
    components: {
        competitionSearch,
        seasonSearch,
        delegateSearch,
        playerSearch,
        areaSearch,
        sportSearch,
        subsportSearch,
    },
    data () {
        let self = (window.x = this)
        return {
            spinShow: false,
            data: {
                id: 0,
                competition: {id:1, name:'夏季奥林匹克运动会'},
                season: {id:1, name:'2020夏季奥林匹克运动会'},
                delegate: {},
                athletes: [],
                area: {},
                sport: {},
                sup_sport: {},
            },
            new_player: {
                id: 0,
                name: '',
            },
            modal_add_player: false,
        }
    },
    methods: {
        now(){
            let self = this;
            var tsp = Date.parse(new Date()) * 0.001;
            tsp -= tsp % 60 ;   // 抛弃秒
            self.$set(self.data, "date", tsp);
        },
        // 获取联赛分类
        getData () {
            let self = this;
            if (self._id && self._id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    "id" : self._id,
                }
                getMedalList( params ).then(response => {
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                        }else{
                            this.data = {};
                        }
                        self.spinShow = false;
                    } else {
                        self.$Message.error(response.data.msg);
                    }
                })
            }else{
                self.data = {
                    id: 0,
                    competition: {id:1, name:'夏季奥林匹克运动会'},
                    season: {id:1, name:'2020夏季奥林匹克运动会'},
                    delegate: {},
                    athletes: [],
                    area: {},
                    sport: {},
                    sup_sport: {},
                }
            }
        },
        // end
        emit(func_name){
            this.$emit('closePage', func_name);
        },

        save() {
            let self = this;
            let athlete_ids = [];
            self.data.athletes.forEach(element => {
                if (element.id > 0 && athlete_ids.indexOf(element.id) == -1 ){
                    athlete_ids.push( element.id )
                }
            });
            let params = {
                id: self.data.id,
                competition_id: self.data.competition.id,
                season_id: self.data.season.id,
                athlete_id: athlete_ids,
                delegate_id: self.data.delegate.id,
                area_id: self.data.area.id,
                sport_id: self.data.sport.id,
                sup_sport_id: self.data.sup_sport.id,
                type: self.data.type,
                result: self.data.result,
                unit: self.data.unit,
                date: self.data.date,
                deleted: self.data.deleted,
            }
            // console.log(params);
            updateMedal(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功");
                    self.emit("save");
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
        // end 
        addNewPlayer(){
            let self = this;
            if (self.new_player.id > 0){
                self.data.athletes.push( self.new_player );
                // 更新奖牌榜的其余信息 - 代表团\国家地区\大运动\小运动
                var params = {
                    "id" : self.new_player.id,
                }
                getPlayerList( params ).then(response => {
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            var player = response.data.data.list[0];
                            if (player){
                                // 国家地区
                                if (player.area.id && player.area.id > 0){
                                    self.data.area = player.area;
                                }
                                // 大小运动
                                let info = player.info || [];
                                if (info.length > 0){
                                    for (var _index in info){
                                        if (info[_index].season.id === self.data.season.id){
                                            // 大运动
                                            self.data.sport = info[_index].sport;
                                            let sub_sports = info[_index].sub_sports;
                                            if (sub_sports && sub_sports.length > 0){
                                                // 小运动
                                                self.data.sup_sport = sub_sports[0];
                                            }
                                            break;
                                        }
                                    }
                                }
                                // 如果属于团体的话 直接将团体下的所有的选手直接写入到数据
                                // console.log(player);
                                if (player.subs && player.subs.length > 0){
                                    // let subs = player.subs;
                                    player.subs.forEach(element => {
                                        self.data.athletes.push( element );
                                    })
                                }
                            }
                        }
                    } else {
                    }
                });
                getDelegationPlayerList({
                    season_id: self.data.season.id,
                    athlete_id: self.new_player.id,
                }).then(response => {
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            var delegate_data = response.data.data.list[0];
                            if (delegate_data){
                                if (delegate_data.delegate && delegate_data.delegate.id > 0){
                                    self.data.delegate = delegate_data.delegate ;
                                }
                            }
                        }
                    } else {
                        self.$Message.error(response.data.msg);
                    }
                })
            }
            self.new_player = {
                id: 0,
                name: '',
            }
        },
    },
    mounted () {
        // let self = this;
    },
    watch: {
        "_id":{
            handler(value){
                // console.log( value );
                let self = this;
                self.getData();
            },
	        immediate: true,  // 深度触发
        }
    },
    computed: {
        date: {
            get: function () {
                if (this.data.date){
                    return moment.unix(this.data.date).toDate();
                }else{
                    return ''; // new Date()  ;
                }
            },
            set: function (newValue) {
                this.data.date = moment(newValue).unix();
            }
        },
    }
}
</script>
