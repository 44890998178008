<template>
  <div>
    <Row style="margin-bottom: 10px;" :gutter="16" align="middle">
        <Col span="4" style="text-align: left">
            奥运会:
        </Col>
        <Col span="16" style="text-align: left">
            <seasonSearch v-model="season"></seasonSearch>
        </Col>
    </Row>
    <Row style="margin-bottom: 10px;" :gutter="16" align="middle">
        <Col span="4" style="text-align: left">
            类型 :
        </Col>
        <Col span="8" style="text-align: left">
            <Select v-model="board_type" >
                <Option :value="1" key="1">总榜单</Option>
                <Option :value="2" key="2">地区榜</Option>
                <Option :value="3" key="3">项目榜</Option>
            </Select>
        </Col>

        <Col span="4" style="text-align: left" v-if="board_type === 2">
            地区:
        </Col>
        <Col span="8" style="text-align: left" v-if="board_type === 2">
            <areaSearch v-model="area"></areaSearch>
        </Col>

        <Col span="4" style="text-align: left" v-if="board_type === 3">
            项目:
        </Col>
        <Col span="8" style="text-align: left" v-if="board_type === 3">
            <sportSearch v-model="sport" :season_id="season.id" ></sportSearch>
        </Col>
    </Row>
    <br />
    <fitTable
        :columns="columns1" 
        :data="data1" 
        :loading="loading" 
        :header_height=150
        v-if="board_type === 1"
    >
    </fitTable>
    <fitTable
        :columns="columns2" 
        :data="data2" 
        :loading="loading" 
        :header_height=150
        v-if="board_type === 2"
    >
    </fitTable>
    <fitTable
        :columns="columns3" 
        :data="data3" 
        :loading="loading" 
        :header_height=150
        v-if="board_type === 3"
    >
    </fitTable>
  </div>
</template>
<script>

import { getMedalBoardList } from '@/api/olympics/medal';
import seasonSearch from '../../season/search.vue';
import areaSearch from '../../area/search.vue';
import sportSearch from '../../sport/search.vue';
import fitTable from '../../../basic/table.vue';

const editButton = (vm, h, params) => {
    let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
            },
            on: {
                click: () => {
                    vm.edit_id = params.row.id;
                    vm.drawer_edit = true;
                }
            }
        },
        '编辑'
    )
}

export default {
    name: "medal-board-list",
    props: {
    },
    components: {
        seasonSearch,
        areaSearch,
        sportSearch,
        fitTable,
    },
    data () {
        let self = (window.x = this)
        return {
            season:{
                id:1, 
                name:'2020夏季奥林匹克运动会'
            },
            board_type: 1,
            area: {
                id: 0,
                name: '',
            },
            sport: {
                id: 0,
                name: '',
            },
            loading: false,
            columns1: [
                {
                    title: '国家/地区',
                    align: 'center',
                    key: 'area',
                    render: function (h, params) {
                        let currentRow = params.row;
                        var area = currentRow.area;
                        return h('span', area.name );
                    }
                },
                
                {
                    title: '男子(金/银/铜)',
                    align: 'center',
                    key: 'man',
                    render: function (h, params) {
                        let currentRow = params.row;
                        var medals = currentRow.medals;
                        if (medals){
                            return h('span', medals.man.join('/') );
                        }else{
                            return h('span', '')
                        }
                    }
                },

                {
                    title: '女子(金/银/铜)',
                    align: 'center',
                    key: 'woman',
                    render: function (h, params) {
                        let currentRow = params.row;
                        var medals = currentRow.medals;
                        if (medals){
                            return h('span', medals.woman.join('/') );
                        }else{
                            return h('span', '')
                        }
                    }
                },
                
                {
                    title: '混合(金/银/铜)',
                    align: 'center',
                    key: 'union',
                    render: function (h, params) {
                        let currentRow = params.row;
                        var medals = currentRow.medals;
                        if (medals){
                            return h('span', medals.union.join('/') );
                        }else{
                            return h('span', '')
                        }
                    }
                },
                {
                    title: '汇总',
                    align: 'center',
                    key: 'union',
                    render: function (h, params) {
                        let currentRow = params.row;
                        var medals = currentRow.medals;
                        if (medals){
                            let man = medals.man;
                            let woman = medals.woman;
                            let union = medals.union;
                            try {
                                var _all = [];
                                _all.push( man[0] + woman[0] + union[0] );    
                                _all.push( man[1] + woman[1] + union[1] );  
                                _all.push( man[2] + woman[2] + union[2] );   
                                return h('span', _all.join('/') );                           
                            } catch (error) {
                                return h('span', '' );
                            }
                        }else{
                            return h('span', '')
                        }
                    }
                },
                // {
                //     title: '类型',
                //     align: 'center',
                //     key: 'type',
                //     render: function (h, params) {
                //         let currentRow = params.row;
                //         // 0普通比赛 1金牌赛 2开幕式 3闭幕式 4颁奖仪式
                //         let types = {
                //             0: '未知',
                //             1: '金牌',
                //             2: '银牌',
                //             3: '铜牌',
                //         }
                //         return h('span', types[ currentRow.type ] || '未知' );
                //     }
                // },
                // {
                //     title: '操作',
                //     key: 'handlers',
                //     align: 'center',
                //     width: 120,
                //     render (h, params) {
                //         return [ editButton( self, h, params) ]
                //     }
                // }
            ],
            columns2: [
                {
                    title: '运动项',
                    align: 'center',
                    key: 'sport',
                    render: function (h, params) {
                        let currentRow = params.row;
                        var sport = currentRow.sport;
                        if (sport){
                            return h('span', sport.name );
                        }else{
                            return h('span', '');
                        }
                    }
                },
                {
                    title: '奖牌(金/银/铜)',
                    align: 'center',
                    key: 'medals',
                    render: function (h, params) {
                        let currentRow = params.row;
                        // console.log("currentRow: ", currentRow);
                        var medals = currentRow.medals;
                        if (medals && typeof(medals) === 'object'){
                            return h('span', medals.join('/') );
                        }else{
                            return h('span', '')
                        }
                    }
                },
            ],
            columns3: [
                {
                    title: '国家/地区',
                    align: 'center',
                    key: 'area',
                    render: function (h, params) {
                        let currentRow = params.row;
                        var area = currentRow.area;
                        if (area){
                            return h('span', area.name );
                        }else{
                            return h('span', '');
                        }
                    }
                },
                {
                    title: '奖牌(金/银/铜)',
                    align: 'center',
                    key: 'medals',
                    render: function (h, params) {
                        let currentRow = params.row;
                        var medals = currentRow.medals;
                        if (medals && typeof(medals) === 'object' ){
                            return h('span', medals.join('/') );
                        }else{
                            return h('span', '')
                        }
                    }
                },
            ],
            data1: [],
            data2: [],
            data3: [],
        }
    },
    methods: {
        // end
        getData () {
            let self = this;
            this.loading = true;
            if (this.season.id > 0){
                if (self.board_type == 1){
                    if (self.season.id > 0){
                    }else{
                        return
                    }
                }else if (self.board_type == 2){
                    if (self.season.id > 0 && this.area.id> 0){
                    }else{
                        return
                    }
                }else if (self.board_type == 3){
                    if (self.season.id > 0 && this.sport.id> 0){
                    }else{
                        return
                    }
                }
                var params = {
                    type: self.board_type,
                    season_id: this.season.id,
                    area_id: this.area.id,
                    sport_id: this.sport.id,
                }
                getMedalBoardList(params).then(response => {
                    if (response.data.code == 0) {
                        if (self.board_type == 1){
                            this.data1 = response.data.data.list;
                        }else if (self.board_type == 2){
                            this.data2 = response.data.data.list;
                        }else if (self.board_type == 3){
                            this.data3 = response.data.data.list;
                        }
                        this.loading = false;
                    } else {
                        this.$Message.error(response.data.msg);
                    }
                })
            }
        },
        // end 
    },
    mounted () {
        this.getData();
    },
    watch: {
        season(){
            let self = this;
            self.getData();
        },
        sport(){
            let self = this;
            self.getData();
        },
        area(){
            let self = this;
            self.getData();
        },
        board_type(){
            let self = this;
            self.getData();
        },
    },
    computed: {}
}
</script>
