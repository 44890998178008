import http from '@/utils/http';

// 获取国家/地区
export const getMedalList = (params) => {
    return http.request({
        url: "/v1/olympics/medal/list",
        method: 'get',
        params: params,
    })
}

// 更新国家/地区
export const updateMedal = (params) => {
    return http.request({
        url: "/v1/olympics/medal/update",
        method: 'post',
        data: params,
    })
}

// 获取榜单列表
export const getMedalBoardList = (params) => {
    return http.request({
        url: "/v1/olympics/medal/board/list",
        method: 'get',
        params: params,
    })
}