<template>
  <div>
    <Drawer
        v-model="drawer_board"
        width="500"
        :mask-closable="true"
    >
        <medalBoardList></medalBoardList>
    </Drawer> 

    <Drawer
        v-model="drawer_edit"
        width="450"
        :mask-closable="true"
    >
        <medalEdit :_id="edit_id" @closePage="closeEditDrawer"></medalEdit>
    </Drawer> 

    <!-- <Form ref="formInline"  label-position="left"  label-colon inline> -->
        <Row >
            <span class='text-main'>奥运会:</span>
            <seasonSearch v-model="season"></seasonSearch>
            <span class='text-main'>日期:</span>
            <Row>
                <Col>
                <DatePicker type='date' format="yyyy-MM-dd" :value="date"  placeholder="选择日期" @on-change="date=$event; onDateChange()" ></DatePicker>
                </Col>
            </Row>
            <span class='text-main'>国家/地区:</span>
            <areaSearch v-model="area"></areaSearch>
            <span class='text-main'>运动:</span>
            <sportSearch v-model="sport" :season_id="season.id"></sportSearch>
            <!-- <span class='text-main'>子运动:</span>
            <subsportSearch v-model="subsport" :season_id="season.id" :sport_id="sport.id"></subsportSearch> -->
            <span class='text-main'>奖牌类型:</span>
            <Row><Col>
                <Select v-model="type_id" @on-change="onTypeIdChange" style="min-width:120px">
                    <Option value="0" :key="0">全部</Option>
                    <Option value="1" :key="1">金牌</Option>
                    <Option value="2" :key="2">银牌</Option>
                    <Option value="3" :key="3">铜牌</Option>
                </Select>
            </Col></Row>
            <Button @click="drawer_board=true">榜单</Button>
            <Button type="success"  @click="add">新增</Button>
        </Row>
    </Form>
    <fitTable 
        :columns="columns" 
        :data="data" 
        :loading="loading" 
    >
    </fitTable>
    <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
    ></Page>
  </div>
</template>
<script>

import { getMedalList } from '@/api/olympics/medal';
import medalEdit from './edit.vue';
import medalBoardList from './board/list.vue';
import seasonSearch from '../season/search.vue';
// import delegateSearch from '../delegation/search.vue';
import areaSearch from '../area/search.vue';
import sportSearch from '../sport/search.vue';
import moment from 'moment';
import fitTable from '../../basic/table.vue';

const editButton = (vm, h, params) => {
    let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
            },
            on: {
                click: () => {
                    vm.edit_id = params.row.id;
                    vm.drawer_edit = true;
                }
            }
        },
        '编辑'
    )
}

export default {
    name: "medal-list",
    props: {
        
    },
    components: {
        medalEdit,
        seasonSearch,
        // delegateSearch,
        medalBoardList,
        areaSearch,
        sportSearch,
        fitTable,
    },
    data () {
        let self = (window.x = this)
        return {
            drawer_board: false,
            season: {
                id: 1,
                name: '2020夏季奥林匹克运动会',
            }, 
            date: '',
            type_id: 0,
            area: {id: 0},
            sport: {id: 0},
            // delegate: {
            //     id: 0,
            //     name: '',
            // },
            drawer_edit: false,
            edit_id : 0,
            search_key: '',
            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    width: 100,
                    key: 'id',
                },
                {
                    title: '赛事',
                    align: 'center',
                    key: 'competition',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var competition = currentRow.competition;
                        return h('span', competition.name );
                    }
                },
                {
                    title: '奥运会',
                    align: 'center',
                    key: 'season',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var season = currentRow.season;
                        return h('span', season.name );
                    }
                },
                {
                    title: '代表团',
                    align: 'center',
                    key: 'delegate',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var delegate = currentRow.delegate;
                        return h('span', delegate.name );
                    }
                },
                {
                    title: '选手',
                    align: 'left',
                    key: 'athletes',
                    minWidth: 150,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let data_list = [];
                        currentRow.athletes.forEach(element => {
                            data_list.push( 
                                // h('Row', element.name),
                                h('Tag', { attrs:{type:"border"} }, element.name),
                            )
                        });
                        if (data_list.length > 0){
                            return data_list
                        }else{
                            return h('span', ' ')
                        }
                    }
                },
                {
                    title: '国家/地区',
                    align: 'center',
                    key: 'area',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var area = currentRow.area;
                        return h('span', area.name );
                    }
                },
                {
                    title: '大运动项',
                    align: 'center',
                    key: 'sport',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var sport = currentRow.sport;
                        return h('span', sport.name );
                    }
                },
                {
                    title: '小运动项',
                    align: 'center',
                    key: 'sup_sport',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var sup_sport = currentRow.sup_sport;
                        return h('span', sup_sport.name );
                    }
                },

                {
                    title: '类型',
                    align: 'center',
                    key: 'type',
                    width: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        // 0普通比赛 1金牌赛 2开幕式 3闭幕式 4颁奖仪式
                        let types = {
                            0: '未知',
                            1: '金牌',
                            2: '银牌',
                            3: '铜牌',
                        }
                        return h('span', types[ currentRow.type ] || '未知' );
                    }
                },
                {
                    title: '日期',
                    align: 'center',
                    key: 'date',
                    width: 100,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var date = currentRow.date;
                        return h('span', date>0? moment.unix(date).format('YYYY-MM-DD HH:mm'): '' );
                    }
                },
                {
                    title: '赛果',
                    align: 'center',
                    key: 'result',
                    minWidth: 80,
                },
                {
                    title: '单位',
                    align: 'center',
                    key: 'unit',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        return h('span', (self.$conf.olympics.result_units[currentRow.unit] || {}).name || '');
                    }
                },

                {
                    title: '操作',
                    key: 'handlers',
                    align: 'center',
                    width: 100,
                    render (h, params) {
                        return [ editButton( self, h, params) ]
                    }
                }
            ],
            data: [],
        }
    },
    methods: {
        onTypeIdChange(){
            this.current_page = 1;
            this.getData();
        },
        onDateChange(){
            this.current_page = 1;
            this.getData();
        },
        onPageChange (page) {
            this.current_page = page
            this.getData()
        },
        // end
        onSearch (search_key) {
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },
        // end
        closeEditDrawer(){
            let self = this;
            self.drawer_edit = false;
            self.edit_id = 0;
            self.getData();
        },
        // end
        getData () {
            this.loading = true;
            if(this.date){
                // console.log(this.date);
                var date = moment( this.date).unix();
            }else{
                var date = 0;
            }

            var params = {
                // search_key: this.search_key,
                page: this.current_page,
                season_id: this.season.id,
                // delegate_id: this.delegate.id,
                date: date,
                type: this.type_id,
                area_id: this.area.id,
                sport_id: this.sport.id,
            }
            getMedalList(params).then(response => {
                if (response.data.code == 0) {
                    this.data = response.data.data.list;
                    this.total_page = response.data.data.total_page;
                    this.page_size = response.data.data.page_size;
                    this.current_page = response.data.data.current_page;
                    this.loading = false;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end 
        add () {
            this.edit_id = 0;
            this.drawer_edit = true;
        },
        // end 
    },
    mounted () {
        this.getData();
    },
    watch: {
        "season.id"(){
            let self = this;
            self.current_page = 1;
            self.getData();
        },
        "sport.id":{
            handler(value){
                // console.log( "sport.id", value );
                let self = this;
                self.current_page = 1;
                self.getData();
            },
	        immediate: false,  // 深度触发
        },
        "area.id":{
            handler(value){
                // console.log( "sport.id", value );
                let self = this;
                self.current_page = 1;
                self.getData();
            },
	        immediate: false,  // 深度触发
        },
        // delegate(){
        //     let self = this;
        //     self.current_page = 1;
        //     self.getData();
        // },
    },
    computed: {}
}
</script>
